import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Spinner, UncontrolledAlert } from 'reactstrap';
import ErrorLine from '../containers/errorLine';
import HardwareDeviceForm from './hardwareDeviceForm';
import * as request from './hardwareRequests';
import { getDeviceConfig, updateDeviceConfig } from '../../api/configurations';
import { AbilityContext, Can } from '../../auth/abilityContext';

const HardwareDevice = ({ row, update }) => {
  const subjectName = 'Hardware';
  const deviceId = +row.original.deviceId;
  const id = +row.original.id;
  const ability = useContext(AbilityContext);
  const canViewConfig = ability.can('view', subjectName, 'deviceConfig');

  const [completeDevice, setCompleteDevice] = useState(null);
  const [completeConfig, setCompleteConfig] = useState(null);
  const [formKey, setFormKey] = useState(1);
  const [updated, setUpdated] = useState(1);

  const {
    loading: loadingData,
    error: errorData,
    data,
  } = useQuery(request.GET_DEVICE, {
    variables: { deviceId },
  });

  const [updateDevice, { loading: updateLoading, error: updateError }] =
    useMutation(request.UPDATE_DEVICE, {
      onCompleted: () => {
        setCompleteDevice(true);
        setFormKey(formKey + 1);
      },
      refetchQueries: [
        {
          query: request.GET_DEVICE,
          variables: { deviceId },
        },
      ],
    });

  const [deviceConfig, setDeviceConfig] = useState(null);
  const [loadingDeviceConfig, setLoadingDeviceConfig] = useState(false);
  const [errorDeviceConfig, setErrorDeviceConfig] = useState(false);

  const fetchData = useCallback(async id => {
    setLoadingDeviceConfig(true);
    try {
      const data = await getDeviceConfig(id);
      setDeviceConfig(data);
    } catch (error) {
      setErrorDeviceConfig(error);
    } finally {
      setLoadingDeviceConfig(false);
    }
  }, []);

  useEffect(() => {
    if (canViewConfig) {
      fetchData(id);
    }
  }, [canViewConfig, fetchData, id]);

  const updateData = async (id, data) => {
    setLoadingDeviceConfig(true);
    try {
      const response = await updateDeviceConfig(id, data);
      if (typeof response === 'number') {
        setCompleteConfig(true);
        if (update) update();
        fetchData(id).then(() => setUpdated(updated + 1));
      }
    } catch (error) {
      setErrorDeviceConfig(error);
    } finally {
      setLoadingDeviceConfig(false);
    }
  };

  const onSubmit = data => {
    const variables = { id: +deviceId, ...data };
    updateDevice({ variables });

    if (ability.can('update', subjectName, 'deviceConfig')) {
      const config = { ...data };
      delete config.name;
      delete config.propertyId;
      updateData(id, { ...config });
    }
  };

  const loading = loadingData || loadingDeviceConfig;

  const success = () => {
    if (canViewConfig) {
      return (
        completeDevice &&
        completeConfig &&
        !loading &&
        !loadingDeviceConfig &&
        !updateError &&
        !errorDeviceConfig
      );
    }
    return completeDevice && !loading && !updateError;
  };

  const dataReady = canViewConfig
    ? !!(data && deviceConfig && !errorDeviceConfig)
    : !!data;

  let formData;

  if (dataReady) {
    if (canViewConfig) {
      formData = {
        ...data,
        ...deviceConfig,
      };
    } else {
      formData = {
        id,
        deviceId,
        typeId: row.original.typeId,
        serialReference: row.original.serialReference,
        macSerial: row.original.macSerial,
        vpnClientAddr: row.original.vpnClientAddr,
        ...data,
      };
    }
  }

  return (
    <>
      {loading && <Spinner color="secondary" />}
      {errorData && <ErrorLine error={errorData} />}
      {errorDeviceConfig && !dataReady && (
        <ErrorLine error={errorDeviceConfig} />
      )}
      {dataReady && (
        <>
          <HardwareDeviceForm
            subjectName={subjectName}
            formData={formData}
            onSubmit={onSubmit}
            formKey={formKey}
            loading={updateLoading || loadingDeviceConfig}
            updated={updated}
          >
            <div>
              {(updateLoading || loadingDeviceConfig) && (
                <Spinner color="secondary" />
              )}
              {updateError && <ErrorLine error={updateError} />}
              {errorDeviceConfig && <ErrorLine error={errorDeviceConfig} />}
              {success() && (
                <UncontrolledAlert>Data has been saved</UncontrolledAlert>
              )}
            </div>
            <Can I="update" a={subjectName}>
              <Button type="submit" color="success">
                Save data
              </Button>
            </Can>
          </HardwareDeviceForm>
        </>
      )}
    </>
  );
};

export default HardwareDevice;
