import gqlTag from 'graphql-tag';

export const GET_DEVICE_OPTIONS = gqlTag`
  query HardwareData {
    deviceTypesList {
      id
      name
    }
    devicesList {
      id
      name
      propertyId
    }
    propertiesList {
      id
      name
      propertySettings {
        id
        logo
      }
    }
  }
`;

export const GET_DEVICE = gqlTag`
  query HardwareData($deviceId: Int!) {
    device(id: $deviceId) {
      id
      name
      propertyId
    }
    propertiesList {
      id
      name
    }
    deviceTypesList {
      id
      name
    }
  }
`;

export const UPDATE_DEVICE = gqlTag`
  mutation UpdateDevice(
    $id: Int!
    $name: String
    $typeId: Int
    $propertyId: Int
  ) {
    updateDevice(
      input: {
        patch: { propertyId: $propertyId, typeId: $typeId, name: $name }
        id: $id
      }
    ) {
      device {
        id
        name
        propertyId
      }
    }
  }
`;
