import React from 'react';
import { useQuery } from '@apollo/client';
import ErrorLine from '../containers/errorLine';
import { Spinner } from 'reactstrap';
import HardwareList from './hardwareList';
import * as request from './hardwareRequests';

const HardwareData = () => {
  const { loading, error, data } = useQuery(request.GET_DEVICE_OPTIONS, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {loading && (
        <div className="py-4">
          <Spinner color="secondary" />
        </div>
      )}
      {error && <ErrorLine error={error} />}
      {data && <HardwareList optionsData={data} />}
    </>
  );
};

export default HardwareData;
