export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'number',
      title: 'id',
    },
    serialReference: {
      type: 'string',
      title: 'Serial reference',
    },
    typeId: {
      type: 'number',
      title: 'Type',
      enum: [],
      enumNames: [],
      default: 3,
    },
    deviceId: {
      type: 'number',
      title: 'Device ID',
    },
    name: {
      type: 'string',
      title: 'Device name',
    },
    propertyId: {
      type: 'number',
      title: 'Property',
      enum: [],
      enumNames: [],
    },
    hwId: {
      type: 'string',
      title: 'HW id',
    },
    macSerial: {
      type: 'string',
      title: 'MAC serial',
    },
    distributorId: {
      type: 'number',
      title: 'Distributor id',
    },
    hddPassword: {
      type: 'string',
      title: 'HDD password',
    },
    sslCaCert: {
      type: 'string',
      title: 'SSL CA certificate',
    },
    sslClientCert: {
      type: 'string',
      title: 'SSL client certificate',
    },
    sslClientKey: {
      type: 'string',
      title: 'SSL client key',
    },
    vpnServerName: {
      type: 'string',
      title: 'VPN server name',
    },
    vpnClientAddr: {
      type: 'string',
      title: 'VPN client address',
    },
    vpnConfig: {
      type: 'string',
      title: 'VPN config',
    },
    kioskHwSrvConfig: {
      type: 'string',
      title: 'Kiosk HW server config',
    },
    kioskDefConfig: {
      type: 'string',
      title: 'Kiosk default config',
    },
  },
};

const monospacedTextarea = {
  'ui:widget': 'textarea',
  'ui:options': {
    rows: 10,
  },
  'classNames': 'monospace',
};
export const uiSchema = {
  sslCaCert: monospacedTextarea,
  sslClientCert: monospacedTextarea,
  sslClientKey: monospacedTextarea,
  vpnConfig: monospacedTextarea,
  kioskHwSrvConfig: monospacedTextarea,
  kioskDefConfig: monospacedTextarea,
};
