import React, { useState, useRef, useCallback } from 'react';
import SEO from '../seo';
import Table from '../containers/table/table';
import ErrorLine from '../containers/errorLine';
import { getDevices } from '../../api/configurations';
import HardwareDevice from './hardwareDevice';
import PropertyLink from '../propertyLink';

const sortById = (a, b) => {
  if (a && b) {
    return a.id - b.id;
  } else {
    return 0;
  }
};

const HardwareList = ({ optionsData }) => {
  const title = 'Hardware';

  const { deviceTypesList, devicesList, propertiesList } = optionsData || {};

  const getItem = useCallback(
    (id, itemList) => itemList.filter(p => p.id === id)[0] || {},
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 60,
        flexGrow: 0,
      },
      {
        Header: 'Serial reference',
        accessor: 'serialReference',
        width: 140,
        flexGrow: 0.5,
      },
      {
        Header: 'MAC serial',
        accessor: 'macSerial',
        width: 140,
        flexGrow: 0.5,
      },
      {
        Header: 'VPN client address',
        accessor: 'vpnClientAddr',
        width: 140,
        flexGrow: 0.5,
      },
      {
        id: 'typeId',
        Header: 'Type',
        accessor: row => getItem(row.typeId, deviceTypesList).name,
        width: 80,
        flexGrow: 0.2,
      },
      {
        Header: 'Device ID',
        accessor: 'deviceId',
        width: 80,
        flexGrow: 0.2,
      },
      {
        id: 'deviceName',
        Header: 'Device name',
        accessor: row => getItem(row.deviceId, devicesList).name,
        width: 150,
        flexGrow: 0.5,
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: row => getItem(row.deviceId, devicesList).propertyId,
        Cell: ({ cell }) => {
          return (
            <PropertyLink property={getItem(cell.value, propertiesList)} />
          );
        },
        width: 150,
        flexGrow: 0.5,
      },
    ],
    [getItem, deviceTypesList, devicesList, propertiesList],
  );

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      try {
        const data = await getDevices(pageSize, pageSize * pageIndex);
        setData(data.data.sort(sortById));
        setTotal(data.total);
        setPageCount(Math.ceil(data.total / pageSize));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <div className="py-4 ">
      <SEO title="Hardware" />
      {error && <ErrorLine error={error} />}
      <Table
        title={title}
        className="py-4"
        columns={columns}
        data={data}
        total={total}
        fetchData={fetchData}
        loading={loading}
        ExpandCard={HardwareDevice}
        options={{
          manualSortBy: true,
          manualPagination: true,
          autoResetPage: false,
          pageCount,
          disableFilters: true,
        }}
      />
    </div>
  );
};

export default HardwareList;
