import React from 'react';
import Layout from '../components/containers/layout';
import HardwareData from '../components/hardware/hardwareData';

const Hardware = () => {
  if (typeof window === 'undefined') return null;
  return (
    <Layout>
      <HardwareData />
    </Layout>
  );
};

export default Hardware;
