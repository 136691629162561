import { useContext, useEffect, useState } from 'react';
import { AbilityContext } from '../auth/abilityContext';

const decodeData = (data, keys) => {
  const decoded = {};
  const hasError = [];
  if (typeof window !== 'undefined') {
    keys.forEach(k => {
      try {
        decoded[k] = window.atob(data[k]);
      } catch (error) {
        console.log(error);
        hasError.push(k);
      }
    });
  }
  return { decoded, hasError };
};

const encodeData = (data, keys) => {
  const encoded = {};
  if (typeof window !== 'undefined') {
    keys.forEach(k => {
      try {
        if (k in data) encoded[k] = window.btoa(data[k]);
      } catch (error) {
        console.log(error);
      }
    });
  }
  return encoded;
};

const forDecode = [
  'sslCaCert',
  'sslClientCert',
  'sslClientKey',
  'vpnConfig',
  'kioskHwSrvConfig',
  'kioskDefConfig',
];

const useHardwareCoding = (
  formDataOriginal,
  onSubmit,
  subjectName,
  updatedData,
) => {
  const ability = useContext(AbilityContext);
  const canViewConfig = ability.can('view', subjectName, 'deviceConfig');

  const [formData, setFormData] = useState(formDataOriginal);
  const [extraErrors, setExtraErrors] = useState({});
  const [updated, setUpdated] = useState(0);

  useEffect(() => {
    const { device } = formDataOriginal || {};
    const { name, propertyId } = device || {};
    if (updatedData > updated) {
      if (canViewConfig) {
        const { decoded, hasError } = decodeData(formDataOriginal, forDecode);
        const errors = {};

        if (hasError.length > 0) {
          hasError.forEach(k => {
            errors[k] = {
              __errors: ['This field cannot be decrypted'],
            };
          });
        }
        setFormData({ ...formDataOriginal, ...decoded, name, propertyId });
        setUpdated(u => u + 1);
        setExtraErrors(errors);
      } else {
        setFormData({ ...formDataOriginal, name, propertyId });
        setUpdated(u => u + 1);
      }
    }
  }, [canViewConfig, formDataOriginal, updated, updatedData]);

  const sendData = data => {
    if (ability.can('update', subjectName, 'deviceConfig')) {
      const forEncode =
        Object.keys(extraErrors).length > 0
          ? forDecode.filter(k => !(k in extraErrors))
          : forDecode;
      onSubmit({ ...data, ...encodeData(data, forEncode) });
    } else {
      onSubmit(data);
    }
  };

  return { formData, extraErrors, updated, onSubmit: sendData };
};

export default useHardwareCoding;
