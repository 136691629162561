import React, { useCallback } from 'react';
import {
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../schemas/hardware';
import FormWrapper from '../../form/formWrapper';
import useHardwareCoding from '../../hooks/useHardwareCoding';

const HardwareDeviceForm = ({
  formData: formDataOriginal,
  onSubmit: sendForm,
  loading,
  subjectName,
  children,
  formKey,
  updated: updatedData,
}) => {
  const { deviceTypesList, propertiesList } = formDataOriginal || {};

  const validate = (formData, errors) => {
    if (Object.keys(extraErrors).length > 0) {
      for (const key of Object.keys(extraErrors)) {
        try {
          window.atob(formData[key]);
        } catch (error) {
          console.error(error);
          errors[key].addError('Check the correctness of the entered data');
        }
      }
    }

    return errors;
  };

  const getOptionLists = useCallback(() => {
    return [
      {
        path: 'typeId',
        enum: deviceTypesList.map(t => t.id),
        enumNames: deviceTypesList.map(t => t.name),
      },
      {
        path: 'propertyId',
        enum: propertiesList.map(p => p.id),
        enumNames: propertiesList.map(p => p.name),
      },
    ];
  }, [deviceTypesList, propertiesList]);

  const { formData, extraErrors, updated, onSubmit } = useHardwareCoding(
    formDataOriginal,
    sendForm,
    subjectName,
    updatedData,
  );

  return (
    <FormWrapper
      formData={formData}
      optionsLists={getOptionLists()}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schemaSource}
      uiSchemaSource={uiSchemaSource}
      onSubmit={onSubmit}
      formKey={formKey}
      children={children}
      extraErrors={extraErrors}
      validate={validate}
      updated={updated}
    />
  );
};

export default HardwareDeviceForm;
